import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Navbar from "./navbar"
import logo from "../images/justez-logo.svg"


class Header extends React.Component{
constructor (props) {
  super(props)
    this.state = {
      display: 'none'
    }
  }
  toggleMenu = (e) => {
    const {
      display
    } = this.state;
    if(display === 'none') {
      this.setState({display: 'block'})
    } else {
      this.setState({display: 'none'})
    }
  }
  render() {
    return(
      <header>
        <div class="header-content">
          <div class="logo">
            <Link to="/">
              <img alt="JustEZ" title="JustEZ" src={logo} />
            </Link>
          </div>
          <Navbar display={this.state.display}></Navbar>
          <div class="hamburger_menu" onClick={this.toggleMenu}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </header>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
