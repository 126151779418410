import React from "react";
import { Link } from "gatsby";
const Navbar = (props) => (
  <ul class="main-nav" style={{display: props.display}}>
    <li>
      <Link activeClassName="active" exact={true} to="/features">
        Features
      </Link>
    </li>
    <li>
      <Link activeClassName="active" exact={true} to="/integrations">
        Integrations
      </Link>
    </li>
    <li>
      <Link activeClassName="active" exact={true} to="/how-it-works">
        How it Works
      </Link>
    </li>
    <li>
      <Link activeClassName="active" exact={true} to="/blog/" target="_blank">
        Blog
      </Link>
    </li>
    <li>
      <a href="https://help.justez.app" target="_blank">
        Help Center
      </a>
    </li>
  </ul>
);
export default Navbar;
