import React from "react"
import { Link } from "gatsby"
import linkedin from "../images/ic-linked-in-w.svg"
import twitter from "../images/ic-twitter-w.svg"
import youtube from "../images/ic-youtube-w.svg"

const Footer = () => (
  <footer>
    <nav>
      <Link activeClassName="active" exact={true} to="/features">Features</Link>|
      <Link activeClassName="active" exact={true} to="/integrations">Integrations</Link>|
      <Link activeClassName="active" exact={true} to="/how-it-works">How it works</Link>|
      <a href="https://help.justez.app" target="_blank">
        Help Center
      </a>{" "}|
      <Link activeClassName="active" exact={true} to="/legal-notices" target="_blank">
        Legal Notices
      </Link>{" "}|<a href="mailto:support@justez.app">Contact</a>
    </nav>
    <div class="social">
      <a href="https://www.linkedin.com/showcase/justez" target="_blank" title="LinkedIn"><img alt="JustEZ" title="LinkedIn" src={linkedin} /></a>
      <a href="https://twitter.com/justezapp" target="_blank" title="Twitter"><img alt="JustEZ" title="Twitter" src={twitter} /></a>
      <a href="https://www.youtube.com/channel/UCvzorNoUiANUe4cVtnUUYOw/featured" target="_blank" title="YouTube"><img alt="JustEZ" title="YouTube" src={youtube} /></a>
    </div>
    <p>
      Copyright 2021 Exzeo, All Rights Reserved.
    </p>
  </footer>
)
export default Footer
